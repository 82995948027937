$(document).ready(function () {
    // set session on change of global filters
    $('#gb_client, #gb_project, #gb_compound').change(function () {
        if (this.id.search("client") != -1)
            var typeName = 'client';
        else if (this.id.search("project") != -1)
            var typeName = 'project';
        else if (this.id.search("compound") != -1)
            var typeName = 'compound';

        $.ajax({
            type: 'post',
            url: baseUrl + '/admin/selection/changeSessionData',
            data: {id: $(this).val(), type: typeName},
            cache: false,
            sync: true,
            success: function () {
                location.reload();
            }
        });
    });
    $('#client').change(function () {
        var clientId = $('#client').val();

        if (clientId !== 0)
        {
            $.ajax({
                type: 'post',
                url: baseUrl + '/admin/selection/getprojectdetail',
                data: {id: clientId},
                cache: false,
                success: function (returndata) {
                    $("#project").find('option').remove();
                    $.each(JSON.parse(returndata), function (id, value) {
                        $("#project").append("<option value=" + value.id + ">" + value.project_code + "</option>");
                    });

                }
            });

        }
    });

    // common validation for only numeric and decimal value
    $(document).on('keyup', '.check-no', function () {

        var $this = $(this);
        var length = this.value.length;
        $this.val($this.val().replace(/[^\d.]/g, ''));
        if (length > 6) {

            return false;
        }
    });


        //common validation for only phone-no and contact-no
    $(document).on('keyup','.phone-no', function (event) {          

        var $this = $(this);

        var reg   = $this.val($this.val().replace(/[^\d\-\s+.]/g, ''));

        if (reg) { 

            return true;

        } else {

            return false;
        }
       
    });



    //common 100 up value validation

    $(document).on('keyup', '.max-per', function () {

        var value = $(this).val();

        var max = 100;

        if (value > max) {

            $(this).val('');

        } else {
            return value;
        }

    });

    // common for create project code and collaborator create code
    $(".code_unit").keypress(function (e) {

        if (e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 9 || e.keyCode == 16 || e.ctrlKey) {
            return true;
        }
        var regex = new RegExp("^[a-zA-Z0-9]+$");
        var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!regex.test(key) || e.keyCode == 190)
        {
            e.preventDefault();
            return false;
        }

    });


});

var O2HPAGESADMIN = {
    loadMainGrid: '',
    init: function ()
    {
        O2HPAGESADMIN.tinyMCE.init();
    },
    // ! O2HPAGESADMIN.tinyMCE
    tinyMCE: {
        // O2HPAGESADMIN.tinyMCE.init
        init: function () {
            tinymce.init({
                selector: 'textarea.tinymce',
                skin: 'oxide',
                plugins: [
                    'link image',
                    'code ',
                ],
                toolbar1: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link code",
                valid_elements: '*[*]',
                relative_urls: true,
                remove_script_host: true
            });
        }
    },
    // ! O2HPAGESADMIN.datagrid
    datagrid: {
        init: function () {
            O2HPAGESADMIN.datagrid.addHandlers();
        },
        // ! O2HPAGESADMIN.datagrid.addHandlers
        addHandlers: function ()
        {
            O2HPAGESADMIN.datagrid.loadData(moduleConfig.gridURL);
        },
        // ! O2HPAGESADMIN.datagrid.subaddHandlers
        subaddHandlers: function ()
        {
            // for paging
            $('.pagination li a').on('click', function (e) {
                e.preventDefault();
                var url = $(this).attr('href');
                O2HPAGESADMIN.datagrid.loadData(url);
            });
            // for delete record
            $('.delete-record').on('click', function (e) {
                e.preventDefault();
                O2HPAGESADMIN.datagrid.deleteItem($(this));
            });
            // for search
            $(":input").on("keydown", function search(e) {
                if (e.keyCode == 13) {
                    O2HPAGESADMIN.datagrid.loadData(moduleConfig.gridURL);
                }
            });
            // for sorting
            $('.sorting').on('click', function () {
                O2HPAGESADMIN.datagrid.sortingDataGrid($(this));
            });
            // to check all the checkbox
            $("#bulkDelete").on('click', function (event) {
                O2HPAGESADMIN.datagrid.selectAll(event);
            });
            // to delete selected items
            $("#trigerDelete").on('click', function (event) {
                O2HPAGESADMIN.datagrid.deleteSelectedItems(event);
            });
            // to active and dactive records
            $(".active-record").on('click', function (event) {
                O2HPAGESADMIN.datagrid.activeSelectedItems(event);
            });

            // to delete selected items
            $("#pagesize").on('change', function () {
                O2HPAGESADMIN.datagrid.loadData(moduleConfig.gridURL);
            });
        },
        // ! O2HPAGESADMIN.datagrid.loadData
        loadData: function (url)
        {
            var fields = $(":input").serializeArray();
            $.post(url, fields, function (data) {
                $('#datagrid').html(data.html);
                O2HPAGESADMIN.datagrid.subaddHandlers();
                $.each(fields, function (i, field) {
                    $("#" + field.name).val(field.value);
                });

                // make other orting columns default
                $('.sorting').each(function () {
                    if ($('#orderby').val() == $(this).attr('data-orderby'))
                    {
                        if ($('#sort').val() == 'asc')
                        {
                            $(this).removeClass().addClass('sorting sorting_desc');
                            $(this).attr('data-sort', 'desc');
                        } else
                        {
                            $(this).removeClass().addClass('sorting sorting_asc');
                            $(this).attr('data-sort', 'asc');
                        }
                    }
                });
            });
        },
        // ! O2HPAGESADMIN.datagrid.sortingDataGrid
        sortingDataGrid: function (event)
        {
            // contain the actual value on click
            var orderBy = event.attr('data-orderby');
            var dataSort = event.attr('data-sort');

            // put value in hidden fields for sorting
            $('#orderby').val(orderBy);
            $('#sort').val(dataSort);
            O2HPAGESADMIN.datagrid.loadData(moduleConfig.gridURL);
            // make other orting columns default
            // $('.sorting').each(function() {
            //     $(this).removeClass().addClass('sorting');
            //     $(this).attr('data-sort','asc');
            // });

            // change the classes according to the sort order
            // if(dataSort == 'asc')
            // {
            //     event.removeClass().addClass('sorting sorting_desc');
            //     event.attr('data-sort','desc');
            // }
            // else
            // {
            //     event.removeClass().addClass('sorting sorting_asc');
            //     event.attr('data-sort','asc');
            // }
        },
        // ! O2HPAGESADMIN.datagrid.deleteItem
        deleteItem: function (event)
        {
            var url = event.attr('href');
            // Need to find this feature
            swal({
                title: "Warning",
                text: "Are you sure you want to delete this item?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: true
            }, function (confirmed) {
                if (confirmed) {
                    $.ajax({
                        url: url,
                        type: 'DELETE',
                        success: function (data) {
                            O2HPAGESADMIN.datagrid.init();
                            if (data.status == "OK")
                                toastr.success(moduleConfig.labelRecordsDeleted);
                            else
                                toastr.error(moduleConfig.labelAuthUnknown);
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            toastr.error(moduleConfig.labelAuthUnknown + ': ' + errorThrown);
                        }
                    });

                }
            });
        },
        // ! O2HPAGESADMIN.datagrid.deleteSelectedItems
        deleteSelectedItems: function (event)
        {
            // Need to find this feature
            swal({
                title: "Warning",
                text: "Are you sure you want to delete this item?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: true
            }, function (confirmed) {
                if (confirmed) {
                    if ($('.deleteRow:checked').length > 0)
                    {
                        // at-least one checkbox checked
                        var ids = [];
                        $('.deleteRow').each(function () {
                            if ($(this).is(':checked')) {
                                ids.push($(this).val());
                            }
                        });
                        $.ajax({
                            url: moduleConfig.deleteSelectedItems,
                            type: "post",
                            data: {params: ids},
                            async: false,
                            success: function (data) {
                                O2HPAGESADMIN.datagrid.init();
                                // O2HPAGESADMIN.loadMainGrid.draw(); // redrawing datatable
                                if (data.status == "OK")
                                    toastr.success(moduleConfig.labelRecordsDeleted);
                                else
                                    toastr.error(moduleConfig.labelAuthUnknown);
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                toastr.error(moduleConfig.labelAuthUnknown + ': ' + errorThrown);
                            }
                        });
                    }
                }
            });
        },
        // ! O2HPAGESADMIN.datagrid.selectAll
        selectAll: function (event)
        {
            var element = jQuery(event.target);
            var status = element.is(':checked');
            $(".deleteRow").each(function () {
                $(this).prop("checked", status);
            });
        },
        // ! O2HPAGESADMIN.datagrid.activeSelectedItems
        activeSelectedItems: function (event)
        {
            var element = jQuery(event.target);
            var action = element.attr('data-url');

            $.ajax({
                url: action,
                async: false,
                success: function (data) {
                    if (data.status == "OK")
                    {
                        if (element.hasClass("fa-remove"))
                        {
                            element.attr('data-url', action.replace('/Active', '/Inactive'));
                            element.removeClass("fa-remove");
                            element.addClass("fa-check");
                        } else
                        {
                            element.attr('data-url', action.replace('/Inactive', '/Active'));
                            element.removeClass("fa-check");
                            element.addClass("fa-remove");
                        }
                        toastr.success(moduleConfig.labelRecordsActivated);
                    } else
                    {
                        toastr.error(moduleConfig.labelAuthUnknown);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    toastr.error(moduleConfig.labelAuthUnknown + ': ' + errorThrown);
                }
            });
        },
    },
    // ! O2HPAGESADMIN.media
    media: {
        init: function () {
            O2HPAGESADMIN.media.addHandlers();
            O2HPAGESADMIN.media.dropZone();
        },
        // ! O2HPAGESADMIN.media.addHandlers
        addHandlers: function () {
            //
        },
        // ! O2HPAGESADMIN.media.dropZone
        dropZone: function () {
            //Dropzone.autoDiscover = false;
            var myDropzone = new Dropzone(".dropzone", {
                paramName: 'media',
                autoProcessQueue: true,
                maxFilesize: '1000',
                acceptedFiles: '.jpg,.jpeg,.png,.mp4',
            });
            myDropzone.on("queuecomplete", function (file, http) {
                window.setTimeout(function () {
                    toastr.success(moduleConfig.labelRecordsDeleted);
                    //location.reload();
                }, 1000);
            });
            myDropzone.on("sending", function (file, fromData) {
                if ($('.alert-danger').length > 0) {
                    $('.alert-danger').remove();
                }
            });
            myDropzone.on("error", function (file, errorMessage) {
                var html = '<div class="alert alert-danger" role="alert">' + errorMessage + '</div>';
                $('.col-md-12').first().prepend(html);
                setTimeout(function () {
                    myDropzone.removeFile(file);
                }, 2000);
            });
        },
    },
    // ! O2HPAGESADMIN.emailTemplate
    emailTemplate: {
        init: function () {
            O2HPAGESADMIN.emailTemplate.addHandlers();
            O2HPAGESADMIN.tinyMCE.init();
        },
        // ! O2HPAGESADMIN.emailTemplate.addHandlers
        addHandlers: function () {
            // to add placeholder in to active textarea
            $("#addPlaceHolder").on('click', function (event) {
                O2HPAGESADMIN.emailTemplate.addPlaceHolder(event);
            });

            $("#showPreview").on('click', function (event) {
                O2HPAGESADMIN.emailTemplate.showPreview(event);
            });

        },
        // ! O2HPAGESADMIN.emailTemplate.addPlaceHolder
        addPlaceHolder: function (event) {
            var placeHolder = $('#placeHolder').val();
            if (placeHolder != '') {
                tinymce.activeEditor.execCommand('mceInsertContent', false, "[" + $('#placeHolder :selected').text() + "]");
            }
        },
        // ! O2HPAGESADMIN.emailTemplate.showPreview
        showPreview: function (event) {
            $('#templateBody').html(tinyMCE.get('txtBody').getContent());
        },
    },
};

// when the page is ready, initialize everything
$(document).ready(function () {
    O2HPAGESADMIN.init();

    $('.datepicker').datepicker({
        format: 'dd-mm-yyyy',
        defaultDate: null,
        autoclose: true,
    });
    
    $('.monthpicker').datepicker({
        autoclose: true,
        defaultDate: null,
        format: 'M yy',
        startView: "months",
        minViewMode: "months"
    });  

    setTimeout(function() {
        $(".alert-success").slideUp("slow");
    }, 5000);
});