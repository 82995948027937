// Design global file
// // JavaScript Document
//$ = $.noConflict();
var O2H = {
	init: function () {
		$(window).resize(function () {
			O2H.loadResize();
		});

		O2H.loadResize();

		//Custom select function
		$(".select2").select2();
	},

	/* ! O2H.loadResize */
	loadResize: function () {
		//load resize script starts
		O2H.jQGRidMobile();


		//script to get windows height and width
		var window_h = $(window).height();
		var window_w = $(window).width();

		//condition for the device width greater then and equal to 768
		if (window_w >= 768) {


		}

		//condition for the device width less then and equal to 768(mobile devices)
		else {

		}
		//load resize script ends
	},

	/**
     jQGRidMobile
     function to make jqgrid responsive
     @version: 2016-07-18 updated (last update date of function)
     @return: what this function returns
		$param: paramters used name with their type
     */
	jQGRidMobile: function () {
		var $grid = $("#list"),
			newWidth = $grid.closest(".ui-jqgrid").parent().width();
		$grid.jqGrid("setGridWidth", newWidth, true);
	},




};

// when the page is ready, initialize everything
$(document).ready(function () {
	O2H.init();


//SLIMSCROLL FOR CHAT WIDGET
  $('.mark-all-accordion, .direct-chat-messages, .scientists, .box-scroll, .responsive-table, .cd-inprogress, .cd-inprogress-dispatch').slimScroll({
	 height: '',
	 alwaysVisible : true,
  });

});

$(function () {

 $('input[type="checkbox"].flat-blue, input[type="radio"].flat-blue').iCheck({
       checkboxClass: 'icheckbox_flat-blue',
       radioClass: 'iradio_flat-blue'
     });



//iCheck for checkbox and radio inputs
    $('input[type="checkbox"].minimal, input[type="radio"].minimal').iCheck({
      checkboxClass: 'icheckbox_minimal-blue',
      radioClass: 'iradio_minimal-blue'
    });
    //Red color scheme for iCheck
    $('input[type="checkbox"].minimal-blue, input[type="radio"].minimal-blue').iCheck({
      checkboxClass: 'icheckbox_minimal-blue',
      radioClass: 'iradio_minimal-blue'
    });

});

/*script for upload input type*/

$('.upload-btn').change(function (e) {
	var inputBox = $(this).parents('.input-type-file').find('.input-file');

	if( this.value != '' ){
		inputBox.addClass('focus-bg');
	}else{
		inputBox.removeClass('focus-bg');
	}
	inputBox.val(this.value);
});

