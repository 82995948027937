function CustomGrid(Required_Key_Value)
    {
        var Default_Key_Value = {
            'inlineEdit' : false, 
            'rowCustomEdit' : false, // action column should show or not param

            //buttons at footer of table
            'addPopup' : false, // add btn
            'editPopup' : false, // edit btn
            'deletePopup' : false, //delete btn
            'refreshFooter' : false, // refresh btn
            'searchFooter' : false, // search btn

            //column wise search enable or not
            'columnFilterToolbar' : false,

             // frozan params
            'setFrozan' : false, // for frozan column
            'useColSpanStyle' : false, // for frozan with group header
            'frozanStartColumnName' : '',
            'frozanNumberOfColumns' : '',
            'frozanTitleText' : '',


            'gridHeight' : '100%', // in % or in pixel
            'gridWidth' : "800", // in % or in pixel
            'tableID' : "list",
            'divId' : "pager",
            'gridCaption' : "",
            'gridLoadUrl' : "",
            'rowEditUrl' : "",
            'rowAddUrl' : "",
            'rowDeleteUrl' : "",


            'displayColumn' : '' ,
            'dbColumn' : '',
            'defaultSortColumnName' : "",
            'defaultSortOrder':"asc",

            'rowNum' : '10',
            'rowList' : ["10","20","50","100"],
            
            //search template params
            'tmplateNames' : "",
            'tmplateValues' : "",

            // show hide column params
            'showHideColumns' : "", // [id of hide,id of show,name of column]

            // grouping params
            'grouping' : false,
            'groupColumn' : '',//group column
            'groupOrder' : '', // order

            // subgrid params , if subGrid:false then other variable does not apply automatically
            'subGrid' : false,
            'subGridLoadUrl' : "",
            'subGridDisplayColumn' : '',
            'subGriddbColumn' : '',
            'subGridDataType' : '',
            'subGridRowNum' : '',
            'subGridSortName' :'',
            'subGridSortOrder' : "",
            'subGridHeight' :'',
            'subGridWidth'  :'',


            // for Column Reordering – New version 3.6
            'sortable' : true,

            // for Row Drag & Drop – New version 3.3
            'sortableRows' : false,

            // for column chooser
            'columnChooser' : false,

            // for bottom dropdown of download
            'downloadDropdown' : [true,true,true,false], //[is_active,csv,xls,pdf]

            // for http://www.trirand.com/jqgridwiki/doku.php?id:wiki%3aoptions
            'shrinkToFit' : false,
            
            /*
             * Added By Niraj for pager
             */
            'viewrecords': true,
            'pginput':true

        };        
        var Final_Key_Value = $.extend( Default_Key_Value, Required_Key_Value );
        
        if($(window).width() <= 768 ){
            Final_Key_Value['refreshFooter'] = false;
            Final_Key_Value['searchFooter'] = false;  
            Final_Key_Value['columnChooser'] = false;
            Final_Key_Value['viewrecords'] = false;
            Final_Key_Value['pginput'] = false;
            Final_Key_Value['rowList'] = false;
        }
        
        $("#"+Final_Key_Value['tableID']).jqGrid({        
        url: Final_Key_Value['gridLoadUrl'],
        editurl: Final_Key_Value['rowEditUrl'], // use for bottom inline edit btn
        addurl: Final_Key_Value['rowAddUrl'], // use for bottom inline edit btn
        datatype: "json",
        mtype: "GET",
        colNames: Final_Key_Value['displayColumn'],
        colModel: Final_Key_Value['dbColumn'],
        pager: "#"+Final_Key_Value['divId'],
        rowNum: Final_Key_Value['rowNum'],
        rowList: Final_Key_Value['rowList'],
        pginput: Final_Key_Value['pginput'],
        sortname: Final_Key_Value['defaultSortColumnName'],
        sortorder: Final_Key_Value['defaultSortOrder'],
        viewrecords: Final_Key_Value['viewrecords'], // [detail of various options - http://www.trirand.com/jqgridwiki/doku.php?id=wiki:options]
        gridview: true,
        autoencode: false,
        //loadonce:true, // if true then paging will be removed and search within record be there
        caption: Final_Key_Value['gridCaption'],
        //setGridWidth : 100,
        shrinkToFit : Final_Key_Value['shrinkToFit'],
        autowidth : true ,
        width: Final_Key_Value['gridWidth'],
	height: Final_Key_Value['gridHeight'],
        grouping:Final_Key_Value['grouping'],
        sortable: Final_Key_Value['sortable'],
   	groupingView : {
   		groupField : [Final_Key_Value['groupColumn']],
   		groupColumnShow : [false],
   		groupText : ['<b>{0}</b>'],
   		groupCollapse : false,
		groupOrder: [Final_Key_Value['groupOrder']],
		groupSummary : [true],
		groupDataSorted : true
   	},
        ajaxRowOptions: { async: true },
        //add the following in one of the colModel config
        
        gridComplete: function(){
            
                // DELETE BUTTON OF GRID
                /**
                * Place the CSRF token as a header on all pages for access in AJAX requests
                */
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                    }
                });

                /**
                 * Add the data-method="delete" forms to all delete links
                 */
                addDeleteForms();

                /**
                 * Generic confirm form delete using Sweet Alert
                 */
                $('body').on('submit', 'form[name=delete_item]', function(e){
                    e.preventDefault();
                    var form = this;
                    var postData = $(this).serializeArray();
                    var formURL = $(this).attr("action");
                    swal({
                        title: "Warning",
                        text: "Are you sure you want to delete this item?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: true
                    }, function(confirmed) {
                        if (confirmed){
                            $.ajax({
                                url: formURL,
                                type: "post",
                                data: postData,
                                success: function(data, status) {
                                    if(data.status == 'OK'){
                                        toastr.success(moduleConfig.labelRecordsDeleted);
                                        jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid");
                                    }
                                },
                                error: function(xhr, desc, err) {
                                  console.log(xhr);
                                  console.log("Details: " + desc + "\nError:" + err);
                                }
                              });
                        }
                            //form.submit();
                    });
                });
                // DELETE BUTTON OF GRID
                
                
                $(".active-record").on('click',function(event) { 
                    
                    var element = jQuery(event.target);
                    var action = element.attr('data-url');

                    $.ajax({
                        url : action,
                        async:false,
                        success: function(data) {            
                            if (data.status == "OK")
                            {
                                if( element.hasClass( "fa-remove" ))
                                {
                                    element.attr('data-url',action.replace('/Active', '/Inactive'));
                                    element.removeClass( "fa-remove" );
                                    element.addClass( "fa-check" );
                                }
                                else
                                {
                                    element.attr('data-url',action.replace('/Inactive', '/Active'));
                                    element.removeClass( "fa-check" );
                                    element.addClass( "fa-remove" );
                                }                                 
                                $("#"+Final_Key_Value['tableID']).trigger('reloadGrid');
                                toastr.success(moduleConfig.labelRecordsActivated);
                            }
                            else
                            {
                                toastr.error(moduleConfig.labelAuthUnknown);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            toastr.error(moduleConfig.labelAuthUnknown+': ' + errorThrown);
                        }
                    });        

                });
                
                
                
                
            
                //$("#_empty","#"+Final_Key_Value['tableID']).addClass("nodrag nodrop");
                //jQuery("#"+Final_Key_Value['tableID']).tableDnDUpdate();
                // for inline columns keep this code for future use
		if(Final_Key_Value['rowCustomEdit']){
                    var ids = jQuery("#"+Final_Key_Value['tableID']).jqGrid('getDataIDs');
                    for(var i=0;i < ids.length;i++){
                            var cl = ids[i];
                            be = "<input style='height:25px;width:25px;' id='e_"+ids[i]+"' type='button' value='DE' onclick='deleteCustom(\""+rowDeleteUrl+"\","+ids[i]+")'>";  
                            se = "<input style='height:25px;width:25px;' type='button' value='S' onclick=\"jQuery('#"+Final_Key_Value['tableID']+"').saveRow('"+cl+"',saveparameters);\" />"; 
                            ce = "<input style='height:25px;width:25px;' type='button' value='C' onclick=\"jQuery('#"+Final_Key_Value['tableID']+"').restoreRow('"+cl+"');\" />"; 
                            jQuery("#"+Final_Key_Value['tableID']).jqGrid('setRowData',ids[i],{act:be+se+ce});
                    }
                    
                    
                    /*var ids = jQuery("#"+Final_Key_Value['tableID']).jqGrid('getDataIDs');
                    for(var i=0;i < ids.length;i++){
                            //alert(ids[i]);
                            saveparameters = {
                                    "successfunc":null,
                                    "url" : rowEditUrl,
                                    "extraparam" : {},
                                    "errorfunc": function (e,data) { 
                                        //jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid");    
                                        jQuery.jgrid.info_dialog(jQuery.jgrid.errors,errorCustomFormate(data),jQuery.jgrid.edit,{buttonalign:'right'});
                                    },
                                    "aftersavefunc" :function(rowid, result) {
                                            jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid"); 
                                          },
                                    "afterrestorefunc" : null,
                                    "restoreAfterError" :false,
                                    "mtype" : "POST"
                            };
                            var cl = ids[i];
                            be='';
                            if(cl !== 'new_row'){
                               be = "<input style='height:25px;width:25px;' id='e_"+ids[i]+"' type='button' value='E' onclick=\"jQuery('#"+Final_Key_Value['tableID']+"').editRow('"+cl+"',false);\"  />";  
                            }
                            se = "<input style='height:25px;width:25px;' type='button' value='S' onclick=\"jQuery('#"+Final_Key_Value['tableID']+"').saveRow('"+cl+"',saveparameters);\" />"; 
                            ce = "<input style='height:25px;width:25px;' type='button' value='C' onclick=\"jQuery('#"+Final_Key_Value['tableID']+"').restoreRow('"+cl+"');\" />"; 
                            jQuery("#"+Final_Key_Value['tableID']).jqGrid('setRowData',ids[i],{act:be+se+ce});
                    }*/
                }
        },
        subGrid: Final_Key_Value['subGrid'],
	subGridRowExpanded: function(subgrid_id, row_id) {
                if(Final_Key_Value['subGrid']){
                    // we pass two parameters
                    // subgrid_id is a id of the div tag created whitin a table data
                    // the id of this elemenet is a combination of the "sg_" + id of the row
                    // the row_id is the id of the row
                    // If we wan to pass additinal parameters to the url we can use
                    // a method getRowData(row_id) - which returns associative array in type name-value
                    // here we can easy construct the flowing
                    var subgrid_table_id, pager_id;
                    subgrid_table_id = subgrid_id+"_t";
                    pager_id = "p_"+subgrid_table_id;
                    $("#"+subgrid_id).html("<table id='"+subgrid_table_id+"' class='scroll'></table><div id='"+pager_id+"' class='scroll'></div>");
                    jQuery("#"+subgrid_table_id).jqGrid({
                            url:Final_Key_Value['subGridLoadUrl']+'&specificid='+row_id,
                            datatype: Final_Key_Value['subGridDataType'],
                            colNames: Final_Key_Value['subGridDisplayColumn'],
                            colModel: Final_Key_Value['subGriddbColumn'],
                            rowNum:Final_Key_Value['subGridRowNum'],
                            pager: pager_id,
                            sortname: Final_Key_Value['subGridSortName'],
                            sortorder: Final_Key_Value['subGridSortOrder'],
                            height: Final_Key_Value['subGridHeight'],
                            width: Final_Key_Value['subGridWidth']
                    });
                    jQuery("#"+subgrid_table_id).jqGrid('navGrid',"#"+pager_id,{edit:false,add:false,del:false})
                }
        },
	subGridRowColapsed: function(subgrid_id, row_id) {
		// this function is called before removing the data
		//var subgrid_table_id;
		//subgrid_table_id = subgrid_id+"_t";
		//jQuery("#"+subgrid_table_id).remove();
	}
        });
    
        
       
        
        
        
        // for botttom pagging display line and options - START
        jQuery("#"+Final_Key_Value['tableID']).jqGrid('navGrid',"#"+Final_Key_Value['divId'],
            // the buttons to appear on the toolbar of the grid
            { edit: Final_Key_Value['editPopup'], add: Final_Key_Value['addPopup'], del: Final_Key_Value['deletePopup'], search: Final_Key_Value['searchFooter'], refresh: Final_Key_Value['refreshFooter']},

            // options for the Edit Dialog
            {
                url:Final_Key_Value['rowEditUrl'], 
                recreateForm: true,
                closeAfterEdit: true,
                errorTextFormat: function (data) {
                    return errorCustomFormate(data);
                }
            },
            // options for the Add Dialog
            {
                url:Final_Key_Value['rowAddUrl'],
                closeAfterAdd: true,
                recreateForm: true,
                errorTextFormat: function (data) {
                    return errorCustomFormate(data);
                }
            },
            // options for the Delete Dailog
            {
                url:Final_Key_Value['rowDeleteUrl'],
                errorTextFormat: function (data) {
                    return errorCustomFormate(data);
                }
            },
            {   // for bottom search with template going server side
                // this need "searchtype" in colModel columns 

                multipleSearch:true,
                multipleGroup:true,
                showQuery: true,
                // set the names of the template
                //"tmplNames" : tmplateNames,
                // set the template contents
                //"tmplFilters": tmplateValues

            }
        );
        // for botttom pagging display line and options - START
        
        
    
        
        
        
         
        // put add custom buttton at bottom , in case btn are given in one column
        /*addBtnParameters={
                    rowID : "new_row",
                    initdata : {},
                    position :"first",
                    useDefValues : false,
                    useFormatter : false,
                    addRowParams : {extraparam:{}}
                }; 
        customparameters={ 
            caption:"Add", 
            buttonicon:"ui-icon-add", 
            onClickButton:function(){ 
                            alert("Adding Row");
                            jQuery('#'+Final_Key_Value['tableID']).addRow(addBtnParameters);
                          }, 
            position: "last", 
            title:"", 
            cursor: "pointer"
        };        
        jQuery("#"+Final_Key_Value['tableID']).jqGrid('navButtonAdd',"#"+Final_Key_Value['divId'],customparameters);*/     
        // put add custom buttton at bottom , in case btn are given in one column
        
        
        // for column search , this need "searchoptions" in colModel columns
        if(Final_Key_Value['columnFilterToolbar']){ 
            jQuery("#"+Final_Key_Value['tableID']).jqGrid('filterToolbar',{searchOperators : true});
        }
    
        // Bottom inline edit 
        if (Final_Key_Value['inlineEdit'])
        {
           parameters = { 
                    edit: true,
                    editicon: "ui-icon-pencil",
                    add: true,
                    addicon:"ui-icon-plus",
                    save: true,
                    saveicon:"ui-icon-disk",
                    cancel: true,
                    cancelicon:"ui-icon-cancel",
                    addParams : {
                        initdata : {},
                        position :"first",
                        useDefValues : false,
                        useFormatter : false,
                        addRowParams : {
                            "url" : Final_Key_Value['rowAddUrl'],
                            "errorfunc": function (e,data) { 
                                    //jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid");    
                                    jQuery.jgrid.info_dialog(jQuery.jgrid.errors,errorCustomFormate(data),jQuery.jgrid.edit,{buttonalign:'right'});
                                },
                            "aftersavefunc" :function(rowid, result) {
                                      jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid"); 
                                      },            
                            },
                            "restoreAfterError" :false,
                            "afterrestorefunc" : null,
                            "mtype" : "POST"

                    },
                    editParams : {
                        "successfunc":null,
                        "url" : Final_Key_Value['rowEditUrl'],
                        "extraparam" : {},
                        "errorfunc": function (e,data) { 
                                //jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid");    
                                jQuery.jgrid.info_dialog(jQuery.jgrid.errors,errorCustomFormate(data),jQuery.jgrid.edit,{buttonalign:'right'});
                            },
                        "aftersavefunc" :function(rowid, result) {
                                        jQuery("#"+Final_Key_Value['tableID']).trigger("reloadGrid"); 
                                      },     
                        "afterrestorefunc" : null,
                        "restoreAfterError" :false,
                        "mtype" : "POST"
                    },
            };
            jQuery("#"+Final_Key_Value['tableID']).jqGrid('inlineNav',"#"+Final_Key_Value['divId'],parameters);
            
            // stop reload if error occure at add time
            jQuery.extend(jQuery.jgrid.inlineEdit, {
                        restoreAfterError: false
                    });
        }
        
            if(Final_Key_Value['useColSpanStyle']){
             jQuery("#"+Final_Key_Value['tableID']).jqGrid('setGroupHeaders', {
                useColSpanStyle: Final_Key_Value['useColSpanStyle'], 
                groupHeaders:[
                      {startColumnName: Final_Key_Value['frozanStartColumnName'], numberOfColumns: Final_Key_Value['frozanNumberOfColumns'], titleText: Final_Key_Value['frozanTitleText']}
                ]	
            });}
        
         if (Final_Key_Value['setFrozan']) {
            jQuery("#"+Final_Key_Value['tableID']).jqGrid('setFrozenColumns');
         } 
         
        if(Final_Key_Value['columnChooser']){ 
            jQuery("#"+Final_Key_Value['tableID']).jqGrid('navButtonAdd',"#"+Final_Key_Value['divId'],{
                caption: "Columns",
                title: "Reorder Columns",
                onClickButton : function (){
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('columnChooser',{
                                                    width: 550, 
                                                    dialog_opts: {
                                                            modal: true,
                                                            minWidth: 470,
                                                            height: 470,
                                                            show: 'blind',
                                                            hide: 'explode',
                                                            dividerLocation: 0.5
                                                    },
                                                });
                }
            });
        }
         
        if(Final_Key_Value['sortableRows']){ 
            jQuery("#"+Final_Key_Value['tableID']).jqGrid('sortableRows', {
                update: function () { //alert('called');
                //resetAltRows.call(this.parentNode);
                }
            });
        }
    
        // EXPORT GRID START 
        var downloadDropdown = Final_Key_Value['downloadDropdown'];
        if(downloadDropdown[0]){ 
            
            var opt = '';
            if(downloadDropdown[1]){
                opt = opt + "<option value=1>Current CSV</option><option value=2>All CSV</option>";
            }
            if(downloadDropdown[2]){
                opt = opt + "<option value=3>Current XLS</option><option value=4>All XLS</option>";
            }
            if(downloadDropdown[3]){
                opt = opt + "<option value=5>Current PDF</option><option value=6>All PDF</option>";
            }
            
            $('#' + Final_Key_Value['divId'] + '_left').append ('<div id="customSelect" style="padding-left: 16px;"><select id="xid"><option value=0>Download Type</option>'
                +opt+
            '</select></div>');

            $('#xid').on('change', function (e) {
                var valueSelected = this.value;
                if(valueSelected == 1){//current csv
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('excelExport',{tag:'csv',"url":Final_Key_Value['gridLoadUrl']+'&dtype=csv&dtotal=current'}); 
                }
                if(valueSelected == 2){//all csv
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('excelExport',{tag:'csv',"url":Final_Key_Value['gridLoadUrl']+'&dtype=csv&dtotal=all'});
                }
                if(valueSelected == 3){//current xls
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('excelExport',{tag:'csv',"url":Final_Key_Value['gridLoadUrl']+'&dtype=xls&dtotal=current'}); 
                }
                if(valueSelected == 4){//all xls
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('excelExport',{tag:'csv',"url":Final_Key_Value['gridLoadUrl']+'&dtype=xls&dtotal=all'}); 
                }
                if(valueSelected == 5){//current pdf
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('excelExport',{tag:'csv',"url":Final_Key_Value['gridLoadUrl']+'&dtype=pdf&dtotal=current'}); 
                }
                if(valueSelected == 6){//all pdf
                    jQuery("#"+Final_Key_Value['tableID']).jqGrid('excelExport',{tag:'csv',"url":Final_Key_Value['gridLoadUrl']+'&dtype=pdf&dtotal=all'}); 
                }
            });
             
        }
       
}





















function CustomGrid1(
        gridHeight,
        gridWidth,
        tableID,
        divId,
        gridCaption,
        gridLoadUrl,
        displayColumn,
        dbColumn,
        defaultSortColumnName,
        defaultSortOrder,
        rowEditUrl,
        rowAddUrl,
        rowDeleteUrl,
        inlineEdit,
        rowCustomEdit,
        addPopup,
        editPopup,
        deletePopup,
        refreshFooter,
        searchFooter,
        columnFilterToolbar,
        rowNum,
        rowList,
        tmplateNames,
        tmplateValues,
        showHideColumns,
        setFrozan,
        useColSpanStyle,
        frozanStartColumnName,
        frozanNumberOfColumns,
        frozanTitleText,
        grouping,
        groupColumn,
        groupOrder,
        subGrid,
        subGridLoadUrl,
        subGridDisplayColumn,
        subGriddbColumn,
        subGridDataType,
        subGridRowNum,
        subGridSortName,
        subGridSortOrder,
        subGridHeight,
        subGridWidth,
        sortable,
        sortableRows,
        columnChooser,
        downloadDropdown,
        shrinkToFit,
        viewrecords
        )
    {
        $("#"+tableID).jqGrid({
        url: gridLoadUrl,
        editurl: rowEditUrl, // use for bottom inline edit btn
        addurl: rowAddUrl, // use for bottom inline edit btn
        datatype: "json",
        mtype: "GET",
        colNames: displayColumn,
        colModel: dbColumn,
        pager: "#"+divId,
        rowNum: rowNum,
        rowList: rowList,
        sortname: defaultSortColumnName,
        sortorder: defaultSortOrder,
        viewrecords: viewrecords, // [detail of various options - http://www.trirand.com/jqgridwiki/doku.php?id=wiki:options]
        gridview: true,
        autoencode: false,
        //loadonce:true, // if true then paging will be removed and search within record be there
        caption: gridCaption,
        //setGridWidth : 100,
        shrinkToFit : shrinkToFit,
        autowidth : true ,
        width: gridWidth,
	height: gridHeight,
        grouping:grouping,
        sortable: sortable,
   	groupingView : {
   		groupField : [groupColumn],
   		groupColumnShow : [false],
   		groupText : ['<b>{0}</b>'],
   		groupCollapse : false,
		groupOrder: [groupOrder],
		groupSummary : [true],
		groupDataSorted : true
   	},
        ajaxRowOptions: { async: true },
        //add the following in one of the colModel config
        
        gridComplete: function(){
            
                // DELETE BUTTON OF GRID
                /**
                * Place the CSRF token as a header on all pages for access in AJAX requests
                */
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                    }
                });

                /**
                 * Add the data-method="delete" forms to all delete links
                 */
                addDeleteForms();

                /**
                 * Generic confirm form delete using Sweet Alert
                 */
                $('body').on('submit', 'form[name=delete_item]', function(e){
                    e.preventDefault();
                    var form = this;
                    var postData = $(this).serializeArray();
                    var formURL = $(this).attr("action");
                    swal({
                        title: "Warning",
                        text: "Are you sure you want to delete this item?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: true
                    }, function(confirmed) {
                        if (confirmed){
                            $.ajax({
                                url: formURL,
                                type: "post",
                                data: postData,
                                success: function(data, status) {
                                    if(data.status == 'OK'){
                                        toastr.success(moduleConfig.labelRecordsDeleted);
                                        jQuery("#"+tableID).trigger("reloadGrid");
                                    }
                                },
                                error: function(xhr, desc, err) {
                                  console.log(xhr);
                                  console.log("Details: " + desc + "\nError:" + err);
                                }
                              });
                        }
                            //form.submit();
                    });
                });
                // DELETE BUTTON OF GRID
                
                
                $(".active-record").on('click',function(event) { 
                    
                    var element = jQuery(event.target);
                    var action = element.attr('data-url');

                    $.ajax({
                        url : action,
                        async:false,
                        success: function(data) {            
                            if (data.status == "OK")
                            {
                                if( element.hasClass( "fa-remove" ))
                                {
                                    element.attr('data-url',action.replace('/Active', '/Inactive'));
                                    element.removeClass( "fa-remove" );
                                    element.addClass( "fa-check" );
                                }
                                else
                                {
                                    element.attr('data-url',action.replace('/Inactive', '/Active'));
                                    element.removeClass( "fa-check" );
                                    element.addClass( "fa-remove" );
                                }                        
                                toastr.success(moduleConfig.labelRecordsActivated);
                            }
                            else
                            {
                                toastr.error(moduleConfig.labelAuthUnknown);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            toastr.error(moduleConfig.labelAuthUnknown+': ' + errorThrown);
                        }
                    });        

                });
                
                
                
                
            
                //$("#_empty","#"+tableID).addClass("nodrag nodrop");
                //jQuery("#"+tableID).tableDnDUpdate();
                // for inline columns keep this code for future use
		if(rowCustomEdit){
                    var ids = jQuery("#"+tableID).jqGrid('getDataIDs');
                    for(var i=0;i < ids.length;i++){
                            var cl = ids[i];
                            be = "<input style='height:25px;width:25px;' id='e_"+ids[i]+"' type='button' value='DE' onclick='deleteCustom(\""+rowDeleteUrl+"\","+ids[i]+")'>";  
                            se = "<input style='height:25px;width:25px;' type='button' value='S' onclick=\"jQuery('#"+tableID+"').saveRow('"+cl+"',saveparameters);\" />"; 
                            ce = "<input style='height:25px;width:25px;' type='button' value='C' onclick=\"jQuery('#"+tableID+"').restoreRow('"+cl+"');\" />"; 
                            jQuery("#"+tableID).jqGrid('setRowData',ids[i],{act:be+se+ce});
                    }
                    
                    
                    /*var ids = jQuery("#"+tableID).jqGrid('getDataIDs');
                    for(var i=0;i < ids.length;i++){
                            //alert(ids[i]);
                            saveparameters = {
                                    "successfunc":null,
                                    "url" : rowEditUrl,
                                    "extraparam" : {},
                                    "errorfunc": function (e,data) { 
                                        //jQuery("#"+tableID).trigger("reloadGrid");    
                                        jQuery.jgrid.info_dialog(jQuery.jgrid.errors,errorCustomFormate(data),jQuery.jgrid.edit,{buttonalign:'right'});
                                    },
                                    "aftersavefunc" :function(rowid, result) {
                                            jQuery("#"+tableID).trigger("reloadGrid"); 
                                          },
                                    "afterrestorefunc" : null,
                                    "restoreAfterError" :false,
                                    "mtype" : "POST"
                            };
                            var cl = ids[i];
                            be='';
                            if(cl !== 'new_row'){
                               be = "<input style='height:25px;width:25px;' id='e_"+ids[i]+"' type='button' value='E' onclick=\"jQuery('#"+tableID+"').editRow('"+cl+"',false);\"  />";  
                            }
                            se = "<input style='height:25px;width:25px;' type='button' value='S' onclick=\"jQuery('#"+tableID+"').saveRow('"+cl+"',saveparameters);\" />"; 
                            ce = "<input style='height:25px;width:25px;' type='button' value='C' onclick=\"jQuery('#"+tableID+"').restoreRow('"+cl+"');\" />"; 
                            jQuery("#"+tableID).jqGrid('setRowData',ids[i],{act:be+se+ce});
                    }*/
                }
        },
        subGrid: subGrid,
	subGridRowExpanded: function(subgrid_id, row_id) {
                if(subGrid){
                    // we pass two parameters
                    // subgrid_id is a id of the div tag created whitin a table data
                    // the id of this elemenet is a combination of the "sg_" + id of the row
                    // the row_id is the id of the row
                    // If we wan to pass additinal parameters to the url we can use
                    // a method getRowData(row_id) - which returns associative array in type name-value
                    // here we can easy construct the flowing
                    var subgrid_table_id, pager_id;
                    subgrid_table_id = subgrid_id+"_t";
                    pager_id = "p_"+subgrid_table_id;
                    $("#"+subgrid_id).html("<table id='"+subgrid_table_id+"' class='scroll'></table><div id='"+pager_id+"' class='scroll'></div>");
                    jQuery("#"+subgrid_table_id).jqGrid({
                            url:subGridLoadUrl+'&specificid='+row_id,
                            datatype: subGridDataType,
                            colNames: subGridDisplayColumn,
                            colModel: subGriddbColumn,
                            rowNum:subGridRowNum,
                            pager: pager_id,
                            sortname: subGridSortName,
                            sortorder: subGridSortOrder,
                            height: subGridHeight,
                            width: subGridWidth
                    });
                    jQuery("#"+subgrid_table_id).jqGrid('navGrid',"#"+pager_id,{edit:false,add:false,del:false})
                }
        },
	subGridRowColapsed: function(subgrid_id, row_id) {
		// this function is called before removing the data
		//var subgrid_table_id;
		//subgrid_table_id = subgrid_id+"_t";
		//jQuery("#"+subgrid_table_id).remove();
	}
        });
    
        
       
        
        
        
        // for botttom pagging display line and options - START
        jQuery("#"+tableID).jqGrid('navGrid',"#"+divId,
            // the buttons to appear on the toolbar of the grid
            { edit: editPopup, add: addPopup, del: deletePopup, search: searchFooter, refresh: refreshFooter},

            // options for the Edit Dialog
            {
                url:rowEditUrl, 
                recreateForm: true,
                closeAfterEdit: true,
                errorTextFormat: function (data) {
                    return errorCustomFormate(data);
                }
            },
            // options for the Add Dialog
            {
                url:rowAddUrl,
                closeAfterAdd: true,
                recreateForm: true,
                errorTextFormat: function (data) {
                    return errorCustomFormate(data);
                }
            },
            // options for the Delete Dailog
            {
                url:rowDeleteUrl,
                errorTextFormat: function (data) {
                    return errorCustomFormate(data);
                }
            },
            {   // for bottom search with template going server side
                // this need "searchtype" in colModel columns 

                multipleSearch:true,
                multipleGroup:true,
                showQuery: true,
                // set the names of the template
                //"tmplNames" : tmplateNames,
                // set the template contents
                //"tmplFilters": tmplateValues

            }
        );
        // for botttom pagging display line and options - START
        
        
    
        
        
        
         
        // put add custom buttton at bottom , in case btn are given in one column
        /*addBtnParameters={
                    rowID : "new_row",
                    initdata : {},
                    position :"first",
                    useDefValues : false,
                    useFormatter : false,
                    addRowParams : {extraparam:{}}
                }; 
        customparameters={ 
            caption:"Add", 
            buttonicon:"ui-icon-add", 
            onClickButton:function(){ 
                            alert("Adding Row");
                            jQuery('#'+tableID).addRow(addBtnParameters);
                          }, 
            position: "last", 
            title:"", 
            cursor: "pointer"
        };        
        jQuery("#"+tableID).jqGrid('navButtonAdd',"#"+divId,customparameters);*/     
        // put add custom buttton at bottom , in case btn are given in one column
        
        
        // for column search , this need "searchoptions" in colModel columns
        if(columnFilterToolbar){ 
            jQuery("#"+tableID).jqGrid('filterToolbar',{searchOperators : true});
        }
    
        // Bottom inline edit 
        if (inlineEdit)
        {
           parameters = { 
                    edit: true,
                    editicon: "ui-icon-pencil",
                    add: true,
                    addicon:"ui-icon-plus",
                    save: true,
                    saveicon:"ui-icon-disk",
                    cancel: true,
                    cancelicon:"ui-icon-cancel",
                    addParams : {
                        initdata : {},
                        position :"first",
                        useDefValues : false,
                        useFormatter : false,
                        addRowParams : {
                            "url" : rowAddUrl,
                            "errorfunc": function (e,data) { 
                                    //jQuery("#"+tableID).trigger("reloadGrid");    
                                    jQuery.jgrid.info_dialog(jQuery.jgrid.errors,errorCustomFormate(data),jQuery.jgrid.edit,{buttonalign:'right'});
                                },
                            "aftersavefunc" :function(rowid, result) {
                                      jQuery("#"+tableID).trigger("reloadGrid"); 
                                      },            
                            },
                            "restoreAfterError" :false,
                            "afterrestorefunc" : null,
                            "mtype" : "POST"

                    },
                    editParams : {
                        "successfunc":null,
                        "url" : rowEditUrl,
                        "extraparam" : {},
                        "errorfunc": function (e,data) { 
                                //jQuery("#"+tableID).trigger("reloadGrid");    
                                jQuery.jgrid.info_dialog(jQuery.jgrid.errors,errorCustomFormate(data),jQuery.jgrid.edit,{buttonalign:'right'});
                            },
                        "aftersavefunc" :function(rowid, result) {
                                        jQuery("#"+tableID).trigger("reloadGrid"); 
                                      },     
                        "afterrestorefunc" : null,
                        "restoreAfterError" :false,
                        "mtype" : "POST"
                    },
            };
            jQuery("#"+tableID).jqGrid('inlineNav',"#"+divId,parameters);
            
            // stop reload if error occure at add time
            jQuery.extend(jQuery.jgrid.inlineEdit, {
                        restoreAfterError: false
                    });
        }
        
            if(useColSpanStyle){
             jQuery("#"+tableID).jqGrid('setGroupHeaders', {
                useColSpanStyle: useColSpanStyle, 
                groupHeaders:[
                      {startColumnName: frozanStartColumnName, numberOfColumns: frozanNumberOfColumns, titleText: frozanTitleText}
                ]	
            });}
        
         if (setFrozan) {
            jQuery("#"+tableID).jqGrid('setFrozenColumns');
         } 
         
        if(columnChooser){ 
            jQuery("#"+tableID).jqGrid('navButtonAdd',"#"+divId,{
                caption: "Columns",
                title: "Reorder Columns",
                onClickButton : function (){
                    jQuery("#"+tableID).jqGrid('columnChooser',{
                                                    width: 550, 
                                                    dialog_opts: {
                                                            modal: true,
                                                            minWidth: 470,
                                                            height: 470,
                                                            show: 'blind',
                                                            hide: 'explode',
                                                            dividerLocation: 0.5
                                                    },
                                                });
                }
            });
        }
         
        if(sortableRows){ 
            jQuery("#"+tableID).jqGrid('sortableRows', {
                update: function () { //alert('called');
                //resetAltRows.call(this.parentNode);
                }
            });
        }
    
        // EXPORT GRID START 
        if(downloadDropdown[0]){ 
            
            var opt = '';
            if(downloadDropdown[1]){
                opt = opt + "<option value=1>Current CSV</option><option value=2>All CSV</option>";
            }
            if(downloadDropdown[2]){
                opt = opt + "<option value=3>Current XLS</option><option value=4>All XLS</option>";
            }
            if(downloadDropdown[3]){
                opt = opt + "<option value=5>Current PDF</option><option value=6>All PDF</option>";
            }
            
            $('#' + divId + '_left').append ('<div id="customSelect" style="padding-left: 16px;"><select id="xid"><option value=0>Download Type</option>'
                +opt+
            '</select></div>');

            $('#xid').on('change', function (e) {
                var valueSelected = this.value;
                if(valueSelected == 1){//current csv
                    jQuery("#"+tableID).jqGrid('excelExport',{tag:'csv',"url":gridLoadUrl+'&dtype=csv&dtotal=current'}); 
                }
                if(valueSelected == 2){//all csv
                    jQuery("#"+tableID).jqGrid('excelExport',{tag:'csv',"url":gridLoadUrl+'&dtype=csv&dtotal=all'});
                }
                if(valueSelected == 3){//current xls
                    jQuery("#"+tableID).jqGrid('excelExport',{tag:'csv',"url":gridLoadUrl+'&dtype=xls&dtotal=current'}); 
                }
                if(valueSelected == 4){//all xls
                    jQuery("#"+tableID).jqGrid('excelExport',{tag:'csv',"url":gridLoadUrl+'&dtype=xls&dtotal=all'}); 
                }
                if(valueSelected == 5){//current pdf
                    jQuery("#"+tableID).jqGrid('excelExport',{tag:'csv',"url":gridLoadUrl+'&dtype=pdf&dtotal=current'}); 
                }
                if(valueSelected == 6){//all pdf
                    jQuery("#"+tableID).jqGrid('excelExport',{tag:'csv',"url":gridLoadUrl+'&dtype=pdf&dtotal=all'}); 
                }
            });
             
        }
       
}

// error formating function
function errorCustomFormate(data){
    var json_obj = JSON.parse(data.responseText);
    var output="";
    for (var i in json_obj) 
        {
            output+="</br>" + json_obj[i];
        }
    return 'Error: ' + output;
}



// return search option use in dbColumn
function getSearchOption(type, searchvalue){
    if(searchvalue === undefined){
        searchvalue = '';
    }
    if(type.toUpperCase() === 'string'.toUpperCase()){
            return {sopt:["bw","cn","nc","ew","eq","ne"]};
    }
    if(type.toUpperCase() === 'integer'.toUpperCase()){
            return {sopt:["eq","ne","le","lt","ge","gt"]};
    }
    if(type.toUpperCase() === 'select'.toUpperCase()){
            return {stype: "select",sopt:["eq"], value: searchvalue, dataInit: function(element) {}};
    }
    if(type.toUpperCase() === 'date'.toUpperCase()){
            return {
                sopt: ['bw'],
                dataInit: function (elem) {
                    $(elem).datepicker({
                        dateFormat: 'yy-mm-dd',
                        onSelect: function (dateText, inst) {
                            $('#list')[0].triggerToolbar();
                        }
                    });
                }
            };
    }
}	



// delete handler
function deleteCustom(url,id){ alert(url);
    var result = confirm("Are you sure you want to delete this item?");
    if (result) {
        $.ajax({
            url: url,
            type: 'post',
            data: {'id': id},
            success: function(data, status) {
                if(data.status == 'OK'){
                    jQuery("#"+tableID).trigger("reloadGrid");
                }
            },
            error: function(xhr, desc, err) {
              console.log(xhr);
              console.log("Details: " + desc + "\nError:" + err);
            }
          });
    }
}


function addDeleteForms() {
    $('[data-method]').append(function () { 
        if (! $(this).find('form').length > 0){
            return "\n" +
                "<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                "   <input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "   <input type='hidden' name='_token' value='" + $('meta[name="_token"]').attr('content') + "'>\n" +
                "</form>\n"
        }else{
            return "";
        }
    })
        .removeAttr('href')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}
$(window).on("resize", function () {

    var $grid = $("#list"),
        newWidth = $grid.closest(".ui-jqgrid").parent().width();
    $grid.jqGrid("setGridWidth", newWidth, true);

});